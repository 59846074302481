import React from 'react';
import styled from 'styled-components'

import { Wrapper } from '../common/wrapper'

import { Heading } from '../common/heading'

import { device } from '../../utils/breakpoints'


const WrapperPrivacyPolicy = styled(Wrapper)`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  min-height: 70vh;
 
`

const ContainerCookies = styled.div `
  padding: 4rem 0;
`

const HeadingPrivacyPolicy = styled(Heading)`
  margin-bottom: 3rem;
  font-size: ${({ theme }) => theme.fonts.size.m};
  color: ${({ theme }) => theme.colors.black};
`

const Paragraph = styled.p `
  font-size: ${({ theme }) => theme.fonts.size.xs};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black};

  
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fonts.size.s};
  }

`



export const CookieComponent = () => {

    return (
        <WrapperPrivacyPolicy>
        <ContainerCookies>
            <HeadingPrivacyPolicy as='h2' h2>
                Cookies
            </HeadingPrivacyPolicy>
            <Paragraph>
                W celu zapewnienia jak najlepszej jakości świadczenia usług. 
                BodyMed Group korzysta z informacji zapisywanych przez serwer na urządzeniu końcowym Klienta, 
                które następnie są odczytywane przy każdorazowym połączeniu się przeglądarki internetowej – pliki cookies. 
                Korzystanie przez Właściciela Strony z plików cookies ma na celu ułatwienie i przyśpieszenie przeglądania 
                przy kolejnej wizycie w serwisie. Użytkownik sam decyduje o tym, które pliki cookies i w jaki sposób są zapisywane, 
                poprzez ustawienia przeglądarki internetowej. Domyślne ustawienia przeglądarek pozwalają na przechowywanie plików cookies.
            </Paragraph>
            </ContainerCookies>
        </WrapperPrivacyPolicy>
    )
}






