import React from "react"

import Layout from "../components/layout"
import { CookieComponent } from '../components/cookieComponent' 
import SEO from '../components/seo'

const LodzCookies = () => (
  <Layout torun>
    <SEO
      title='Lodz Cookies'
      description='Cookies'
      url='https://bodymedgroup.pl/lodz-cookies'
    />

    <CookieComponent/>
  </Layout>
)

export default LodzCookies
